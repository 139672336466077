import dynamic from 'next/dynamic'
import { useEffect, useRef, useState } from 'react'

import useVisible from '../../../hooks/useVisible'
import global from '../../../styles/Global.module.scss'
import css from './Privilege.module.scss'

const DynamicStairsSlider = dynamic(
  () => import('../../general/slider/StairsSlider'),
  { ssr: false }
)

const DEFAULT_BLOCK_TITLE = `MY <span>преимущества</span>`

const Privilege = ({ data, customTitle, isMainPage = true }) => {
  //animation
  const ref = useRef(null)
  const { isVisible } = useVisible(ref)
  //

  const [visible, setVisible] = useState(false)

  const title = customTitle || DEFAULT_BLOCK_TITLE

  useEffect(() => {
    isVisible && !visible && setVisible(true)
  }, [isVisible])

  return (
    <section className={`${css.privilege} ${!isMainPage && `${css.bgStyles}`}`} ref={ref}>
      <div className={global.section}>
        <h2
          className={
            isVisible
              ? `${css.animation} ${css.title} ${isMainPage ? "main-page__title" : `${css.titleSmall}`} main-page__advantages`
              : `${css.title} main-page__title main-page__advantages`
          }
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div
          className={
            isVisible ? `${css.animation} ${css.slider}` : `${css.slider}`
          }
        >
          {visible && <DynamicStairsSlider data={data} isMainPage={isMainPage}/>}
        </div>
      </div>
    </section>
  )
}

export default Privilege
